import React, { useState } from 'react';
import MapComponent from './MapComponent';
import Dashboard from './Dashboard';
import AdsList from './AdsList';
import UnifiedFilters from './UnifiedFilters';
import './App.css';

const App = () => {
  const [filters, setFilters] = useState({
    propertyTypes: [],
    rentOrSell: 'all',
    priceRange: [0, Infinity],
    yieldRange: [0, Infinity],
  });

  const updateFilters = (newFilters) => {
    setFilters(newFilters);
  };

  return (
    <div className="app-container">
      <div className="sidebar-filters">
        <UnifiedFilters filters={filters} updateFilters={updateFilters} />
      </div>
      <div className="content-container">
        <div className="map-and-list-container">
          <div className="map-container">
            <MapComponent filters={filters} />
          </div>
          <div className="ads-list-container">
            <AdsList filters={filters} />
          </div>
        </div>
        <div className="dashboard-container">
          <Dashboard filters={filters} />
        </div>
      </div>
    </div>
  );
};

export default App;
