import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  Legend,
} from "recharts";
import "./Dashboard.css";

const Dashboard = ({ filters }) => {
  const [realEstateData, setRealEstateData] = useState([]);
  const [overallTrends, setOverallTrends] = useState([]);
  const [categoryTrends, setCategoryTrends] = useState([]);

  const propertyTypes = [
    "1-room flat",
    "2-room flat",
    "3-room flat",
    "4-room flat",
    "5-room flat",
    "House",
    "Land",
  ];
  const colors = [
    "#8884d8",
    "#82ca9d",
    "#ffc658",
    "#ff7300",
    "#ff0000",
    "#00C49F",
    "#FFBB28",
  ];

  useEffect(() => {
    // Load real estate price data
    Papa.parse("real-estate-price-by-region.csv", {
      download: true,
      header: true,
      delimiter: ";",
      complete: (results) => {
        // console.log('Parsed Real Estate CSV Data:', results.data);
        setRealEstateData(results.data);
      },
    });

    // Load overall trends data
    Papa.parse("overall_trend.csv", {
      download: true,
      header: true,
      complete: (results) => {
        // console.log('Parsed Overall Trends Data:', results.data);
        setOverallTrends(results.data);
      },
    });

    // Load category trends data
    Papa.parse("category_trend.csv", {
      download: true,
      header: true,
      complete: (results) => {
        // console.log('Parsed Category Trends Data:', results.data);
        setCategoryTrends(results.data);
      },
    });
  }, []);

  const formatRealEstateData = (data) => {
    return data
      .filter((row) => row["Rok, Štvrťrok"].includes("Q")) // Include only rows with 'Q' in 'Rok, Štvrťrok'
      .map((row) => {
        const yearQuarter = row["Rok, Štvrťrok"];
        const poValue = row["PO"]; // Extract the value for the PO column

        if (poValue && typeof poValue === "string") {
          const price = parseFloat(poValue.replace(/,|\s/g, ""));
          return {
            date: yearQuarter,
            value: isNaN(price) ? null : price,
            sortValue: yearQuarterToComparable(yearQuarter), // Convert yearQuarter to a comparable value
          };
        }

        return { date: yearQuarter, value: null, sortValue: null };
      })
      .filter((dataPoint) => dataPoint.value !== null) // Remove rows with null values
      .sort((a, b) => a.sortValue - b.sortValue); // Sort by the comparable value
  };

  const yearQuarterToComparable = (yearQuarter) => {
    const [quarter, year] = yearQuarter.split(" ");
    const quarterNumber = parseInt(quarter[0]); // Extract the quarter number
    return parseInt(year) * 10 + quarterNumber; // Create a comparable value (e.g., 20242 for 2Q 2024)
  };

  const formatTrendData = (data) => {
    return data
      .map((item) => ({
        ...item,
        date: new Date(item.date).toLocaleDateString(),
        avg_price: parseFloat(item.avg_price),
        median_price: parseFloat(item.median_price),
        total_ads: parseInt(item.total_ads),
      }))
      .sort((a, b) => new Date(a.date) - new Date(b.date));
  };

  const formatCategoryTrendData = (data) => {
    const formattedData = data
      .map((item) => ({
        ...item,
        date: new Date(item.date).toLocaleDateString(),
        avg_price: parseFloat(item.avg_price),
        median_price: parseFloat(item.median_price),
        ads_count: parseInt(item.ads_count),
        avg_yield: parseFloat(item.avg_yield),
        median_yield: parseFloat(item.median_yield),
      }))
      .sort((a, b) => new Date(a.date) - new Date(b.date));
  
    const groupedData = formattedData.reduce((acc, item) => {
      if (!acc[item.date]) {
        acc[item.date] = { date: item.date };
      }
      acc[item.date][`avg_${item.n_rooms}`] = item.avg_price;
      acc[item.date][`median_${item.n_rooms}`] = item.median_price;
      acc[item.date][`count_${item.n_rooms}`] = item.ads_count;
      acc[item.date][`avg_yield_${item.n_rooms}`] = item.avg_yield;
      acc[item.date][`median_yield_${item.n_rooms}`] = item.median_yield;
      return acc;
    }, {});
    
    console.log('Grouped Data:', groupedData);
    return Object.values(groupedData);
  };
  

  const filterChartData = (data) => {
    if (filters.propertyTypes.length === 0) {
      return data; // Return all data if no filters are selected
    }
    
    return data.map((item) => {
      const filteredItem = { date: item.date };
  
      filters.propertyTypes.forEach((type) => {
        // Filter average price
        if (item[`avg_${type}`]) {
          filteredItem[`avg_${type}`] = item[`avg_${type}`];
        }
  
        // Filter median price
        if (item[`median_${type}`]) {
          filteredItem[`median_${type}`] = item[`median_${type}`];
        }
  
        // Filter ad count
        if (item[`count_${type}`]) {
          filteredItem[`count_${type}`] = item[`count_${type}`];
        }
  
        // Filter average yield
        if (item[`avg_yield_${type}`]) {
          filteredItem[`avg_yield_${type}`] = item[`avg_yield_${type}`];
        }
  
        // Filter median yield
        if (item[`median_yield_${type}`]) {
          filteredItem[`median_yield_${type}`] = item[`median_yield_${type}`];
        }
      });
  
      return filteredItem;
    });
  };

  return (
    <div className="dashboard-container">
      <div className="dashboard">
        <h2>Real Estate Market Trends</h2>
  
        {/* Historical Real Estate Prices NBS */}
        <div className="chart-container">
          <h3>Historical Real Estate Prices NBS</h3>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={formatRealEstateData(realEstateData)}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis
                label={{
                  value: "Prices of Real Estate (€)",
                  angle: -90,
                  position: "insideLeft",
                }}
              />
              <Tooltip />
              <Line type="monotone" dataKey="value" stroke="#8884d8" />
            </LineChart>
          </ResponsiveContainer>
        </div>
  
        {/* Overall Market Trends */}
        <div className="chart-container">
          <h3>Overall Market Trends</h3>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={formatTrendData(overallTrends)}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis
                yAxisId="left"
                label={{
                  value: "Price (€)",
                  angle: -90,
                  position: "insideLeft",
                }}
              />
              <YAxis
                yAxisId="right"
                orientation="right"
                label={{
                  value: "Number of Ads",
                  angle: 90,
                  position: "insideRight",
                }}
              />
              <Tooltip />
              <Legend />
              <Line
                yAxisId="left"
                type="monotone"
                dataKey="avg_price"
                stroke="#8884d8"
                name="Average Price"
              />
              <Line
                yAxisId="left"
                type="monotone"
                dataKey="median_price"
                stroke="#82ca9d"
                name="Median Price"
              />
              <Line
                yAxisId="right"
                type="monotone"
                dataKey="total_ads"
                stroke="#ffc658"
                name="Total Ads"
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
  
        {/* Average Price Trends by Property Type */}
        <div className="chart-container">
          <h3>Average Price Trends by Property Type</h3>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={filterChartData(formatCategoryTrendData(categoryTrends))}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis
                label={{
                  value: "Average Price (€)",
                  angle: -90,
                  position: "insideLeft",
                }}
              />
              <Tooltip />
              <Legend />
              {filters.propertyTypes.length === 0
                ? propertyTypes.map((type) => (
                    <Line
                      key={type}
                      type="monotone"
                      dataKey={`avg_${type}`}
                      stroke={colors[propertyTypes.indexOf(type)]}
                      name={type}
                    />
                  ))
                : filters.propertyTypes.map((type) => (
                    <Line
                      key={type}
                      type="monotone"
                      dataKey={`avg_${type}`}
                      stroke={colors[propertyTypes.indexOf(type)]}
                      name={type}
                    />
                  ))}
            </LineChart>
          </ResponsiveContainer>
        </div>
  
        {/* Median Price Trends by Property Type */}
        <div className="chart-container">
          <h3>Median Price Trends by Property Type</h3>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={filterChartData(formatCategoryTrendData(categoryTrends))}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis
                label={{
                  value: "Median Price (€)",
                  angle: -90,
                  position: "insideLeft",
                }}
              />
              <Tooltip />
              <Legend />
              {filters.propertyTypes.length === 0
                ? propertyTypes.map((type) => (
                    <Line
                      key={type}
                      type="monotone"
                      dataKey={`median_${type}`}
                      stroke={colors[propertyTypes.indexOf(type)]}
                      name={type}
                    />
                  ))
                : filters.propertyTypes.map((type) => (
                    <Line
                      key={type}
                      type="monotone"
                      dataKey={`median_${type}`}
                      stroke={colors[propertyTypes.indexOf(type)]}
                      name={type}
                    />
                  ))}
            </LineChart>
          </ResponsiveContainer>
        </div>
  
        {/* Ad Count Trends by Property Type */}
        <div className="chart-container">
          <h3>Ad Count Trends by Property Type</h3>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={filterChartData(formatCategoryTrendData(categoryTrends))}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis
                label={{
                  value: "Number of Ads",
                  angle: -90,
                  position: "insideLeft",
                }}
              />
              <Tooltip />
              <Legend />
              {filters.propertyTypes.length === 0
                ? propertyTypes.map((type) => (
                    <Line
                      key={type}
                      type="monotone"
                      dataKey={`count_${type}`}
                      stroke={colors[propertyTypes.indexOf(type)]}
                      name={type}
                    />
                  ))
                : filters.propertyTypes.map((type) => (
                    <Line
                      key={type}
                      type="monotone"
                      dataKey={`count_${type}`}
                      stroke={colors[propertyTypes.indexOf(type)]}
                      name={type}
                    />
                  ))}
            </LineChart>
          </ResponsiveContainer>
        </div>
  
        {/* Average ROI Trends by Property Type */}
        <div className="chart-container">
          <h3>Average ROI Trends by Property Type</h3>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={filterChartData(formatCategoryTrendData(categoryTrends))}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis
                label={{
                  value: "Average ROI",
                  angle: -90,
                  position: "insideLeft",
                }}
              />
              <Tooltip />
              <Legend />
              {filters.propertyTypes.length === 0
                ? propertyTypes.map((type) => (
                    <Line
                      key={type}
                      type="monotone"
                      dataKey={`avg_yield_${type}`}
                      stroke={colors[propertyTypes.indexOf(type)]}
                      name={type}
                    />
                  ))
                : filters.propertyTypes.map((type) => (
                    <Line
                      key={type}
                      type="monotone"
                      dataKey={`avg_yield_${type}`}
                      stroke={colors[propertyTypes.indexOf(type)]}
                      name={type}
                    />
                  ))}
            </LineChart>
          </ResponsiveContainer>
        </div>
  
        {/* Median ROI Trends by Property Type */}
        <div className="chart-container">
          <h3>Median ROI Trends by Property Type</h3>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={filterChartData(formatCategoryTrendData(categoryTrends))}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis
                label={{
                  value: "Median ROI",
                  angle: -90,
                  position: "insideLeft",
                }}
              />
              <Tooltip />
              <Legend />
              {filters.propertyTypes.length === 0
                ? propertyTypes.map((type) => (
                    <Line
                      key={type}
                      type="monotone"
                      dataKey={`median_yield_${type}`}
                      stroke={colors[propertyTypes.indexOf(type)]}
                      name={type}
                    />
                  ))
                : filters.propertyTypes.map((type) => (
                    <Line
                      key={type}
                      type="monotone"
                      dataKey={`median_yield_${type}`}
                      stroke={colors[propertyTypes.indexOf(type)]}
                      name={type}
                    />
                  ))}
            </LineChart>
          </ResponsiveContainer>
        </div>
  
      </div>
    </div>
  );
  
};

export default Dashboard;
