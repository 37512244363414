import React, { useEffect, useState } from 'react';
import * as d3 from 'd3-fetch';
import './AdsList.css';

const AdsList = ({ filters }) => {
  const [ads, setAds] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await d3.csv("/data.csv");
        // Filter the ads based on the current filters
        const filteredAds = data.filter(ad => {
          const yieldPercentage = parseFloat(ad.yield_percentage);
          const isValidYield = !isNaN(yieldPercentage);

          return (
            (filters.propertyTypes.length === 0 || filters.propertyTypes.includes(ad.n_rooms)) &&
            (filters.rentOrSell === 'all' || ad.rent_or_sell.trim().toLowerCase() === filters.rentOrSell.trim().toLowerCase()) &&
            parseFloat(ad.price) >= filters.priceRange[0] &&
            parseFloat(ad.price) <= filters.priceRange[1] &&
            (isValidYield ? (yieldPercentage >= filters.yieldRange[0] && yieldPercentage <= filters.yieldRange[1]) : true) // Include ads with missing ROI
          );
        });

        // Update state with filtered ads
        setAds(filteredAds);
      } catch (error) {
        console.error('Error loading CSV:', error);
      }
    };

    // Fetch data whenever filters change
    fetchData();
  }, [filters]);

  return (
    <div className="ads-list">
      <h3>Ads List</h3>
      {ads.length > 0 ? (
        ads.map((ad, index) => (
          <div key={`${ad.name}-${index}`} className="ad-item">
            <div className="ad-header">
              <h4>{ad.name}</h4>
              <span className={`ad-type ${ad.rent_or_sell.toLowerCase()}`}>{ad.rent_or_sell}</span>
            </div>
            <div className="ad-details">
              <div className="ad-info">
                <p>Price: {ad.price}</p>
                <p>Size: {ad.m2} m²</p>
                <p>Address: {ad.address}</p>
                <p>Rooms: {ad.n_rooms}</p>
              </div>
              <div className="ad-stats">
                <p>Yield: {ad.yield_percentage !== 'N/A' ? `${ad.yield_percentage}%` : 'N/A'}</p>
                <p>Price to Rent Ratio: {ad.price_to_rent}</p>
              </div>
            </div>
            <div className="ad-footer">
              <span className="ad-address">{ad.address}</span>
              <a href={ad.url} className="view-button" target="_blank" rel="noopener noreferrer">View</a>
            </div>
          </div>
        ))
      ) : (
        <p>No ads found.</p>
      )}
      <button className="load-more-button">Load More</button>
    </div>
  );
};

export default AdsList;
