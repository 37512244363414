import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import * as d3 from "d3-fetch";
import * as d3Scale from "d3-scale";
import "mapbox-gl/dist/mapbox-gl.css";
import "./MapComponent.css";

const MapComponent = ({ filters }) => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const [allFeatures, setAllFeatures] = useState([]);

  const colorScheme = [
    "#4C0035", "#650031", "#7F0023", "#98000A",
    "#B21800", "#CB4600", "#E57F00", "#FFC300"
  ];

  const getQuantileColorScale = (data) => {
    const yieldValues = data.map(d => d.properties.yield_percentage).filter(y => !isNaN(y));
    return d3Scale.scaleQuantile()
      .domain(yieldValues)
      .range(colorScheme);
  };

  const getPriceColorScale = (data) => {
    const priceValues = data.map(d => parseFloat(d.properties.price)).filter(p => !isNaN(p));
    return d3Scale.scaleLinear()
      .domain([Math.min(...priceValues), Math.max(...priceValues)])
      .range([colorScheme[0], colorScheme[colorScheme.length - 1]]);
  };

  useEffect(() => {
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

    if (!mapRef.current) {
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: "mapbox://styles/mapbox/streets-v11",
        center: [21.2333, 48.9985],
        zoom: 12,
      });

      mapRef.current.scrollZoom.disable();

      mapRef.current.on("load", () => {
        d3.csv("/data.csv")
          .then((data) => {
            const features = data.map((point) => {
              const yieldPercentage = parseFloat(point.yield_percentage);
              return {
                type: "Feature",
                geometry: {
                  type: "Point",
                  coordinates: [parseFloat(point.longitude), parseFloat(point.latitude)],
                },
                properties: {
                  name: point.name || "No Title",
                  price: point.price || "N/A",
                  m2: point.m2 || "N/A",
                  address: point.address || "N/A",
                  n_rooms: point.n_rooms || "N/A",
                  rent_or_sell: point.rent_or_sell || "N/A",
                  rent_price_median: point.rent_price_median || "N/A",
                  rent_price_std: point.rent_price_std || "N/A",
                  price_to_rent: point.price_to_rent || "N/A",
                  yield_percentage: yieldPercentage || "N/A",
                  average_mortgage_payment: point.average_mortgage_payment || "N/A",
                  diff_rent_mortgage_median: point.diff_rent_mortgage_median || "N/A",
                  url: point.url || "",
                  description: `
                    <strong>${point.name || "No Title"}</strong><br>
                    <strong>Price:</strong> ${point.price || "N/A"}<br>
                    <strong>Size (m²):</strong> ${point.m2 || "N/A"}<br>
                    <strong>Address:</strong> ${point.address || "N/A"}<br>
                    <strong>Rooms:</strong> ${point.n_rooms || "N/A"}<br>
                    <strong>Rent or Sell:</strong> ${point.rent_or_sell || "N/A"}<br>
                    <strong>Rent Price Median:</strong> ${point.rent_price_median || "N/A"}<br>
                    <strong>Rent Price Std:</strong> ${point.rent_price_std || "N/A"}<br>
                    <strong>Price to Rent Ratio:</strong> ${point.price_to_rent || "N/A"}<br>
                    <strong>ROI (return on investment):</strong> ${point.yield_percentage || "N/A"}<br>
                    <strong>Average Mortgage Payment:</strong> ${point.average_mortgage_payment || "N/A"}<br>
                    <strong>Difference Rent vs Mortgage Median:</strong> ${point.diff_rent_mortgage_median || "N/A"}<br>
                    <strong>URL:</strong> <a href="${point.url}" target="_blank">${point.url}</a><br>
                  `,
                  yield_percentage: yieldPercentage,
                },
              };
            });

            setAllFeatures(features);
            // console.log('All Features:', features);

            // Initialize the map with features
            const colorScale = filters.rentOrSell === 'rent' ? getPriceColorScale(features) : getQuantileColorScale(features);
            features.forEach(feature => {
              feature.properties.color = filters.rentOrSell === 'rent' ? colorScale(parseFloat(feature.properties.price) || 0) : colorScale(feature.properties.yield_percentage || 0);
            });

            mapRef.current.addSource("places", {
              type: "geojson",
              data: {
                type: "FeatureCollection",
                features: features,
              },
            });

            mapRef.current.addLayer({
              id: "places",
              type: "circle",
              source: "places",
              paint: {
                "circle-color": ["get", "color"],
                "circle-radius": 8,
                "circle-stroke-width": 2,
                "circle-stroke-color": "#fff",
              },
            });

            mapRef.current.on("click", "places", (e) => {
              const coordinates = e.features[0].geometry.coordinates.slice();
              const description = e.features[0].properties.description;

              while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
              }

              new mapboxgl.Popup().setLngLat(coordinates).setHTML(description).addTo(mapRef.current);
            });

            mapRef.current.on("mouseenter", "places", () => {
              mapRef.current.getCanvas().style.cursor = "pointer";
            });

            mapRef.current.on("mouseleave", "places", () => {
              mapRef.current.getCanvas().style.cursor = "";
            });
          })
          .catch((error) => console.error("Error loading CSV:", error));
      });
    }

    const handleKeyDown = (event) => {
      if (event.key === "Meta" || event.key === "Control") {
        mapRef.current.scrollZoom.enable();
      }
    };

    const handleKeyUp = (event) => {
      if (event.key === "Meta" || event.key === "Control") {
        mapRef.current.scrollZoom.disable();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (mapRef.current && mapRef.current.getSource("places")) {
      const filteredFeatures = allFeatures.filter((feature) => {
        const yieldPercentage = parseFloat(feature.properties.yield_percentage);
        const isValidYield = !isNaN(yieldPercentage);
  
        const matchesRooms = filters.propertyTypes.length === 0 || filters.propertyTypes.includes(feature.properties.n_rooms);
        const matchesRentOrSell = filters.rentOrSell === "all" || feature.properties.rent_or_sell.trim().toLowerCase() === filters.rentOrSell.trim().toLowerCase();
        const matchesPriceRange = parseFloat(feature.properties.price) >= filters.priceRange[0] && parseFloat(feature.properties.price) <= filters.priceRange[1];
        const matchesYieldRange = (isValidYield ? (yieldPercentage >= filters.yieldRange[0] && yieldPercentage <= filters.yieldRange[1]) : true);
  
        return matchesRooms && matchesRentOrSell && matchesPriceRange && matchesYieldRange;
      });
  
      // Update color scale based on the filtered features and filter type
      const colorScale = filters.rentOrSell === 'rent' ? getPriceColorScale(filteredFeatures) : getQuantileColorScale(filteredFeatures);
      const updatedFeatures = filteredFeatures.map((feature) => ({
        ...feature,
        properties: {
          ...feature.properties,
          color: filters.rentOrSell === 'rent' ? colorScale(parseFloat(feature.properties.price) || 0) : colorScale(feature.properties.yield_percentage || 0),
        },
      }));
  
      mapRef.current.getSource("places").setData({
        type: "FeatureCollection",
        features: updatedFeatures,
      });
  
      mapRef.current.setPaintProperty("places", "circle-color", ["get", "color"]);
    }
  }, [filters, allFeatures]);

  return (
    <div ref={mapContainerRef} style={{ height: "100%", width: "100%" }} />
  );
};

export default MapComponent;
